import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component<any, any> {
  static displayName = NavMenu.name;

    constructor (props : any) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            <Container fluid>
                <NavbarBrand tag={Link} to="/" style={{ marginLeft: '5px' }}>
                    <img src="/sandwich.svg" alt="Sandwich" height="32" style={{ marginBottom: '7px' }} />
                </NavbarBrand>
            <NavbarBrand tag={Link} to="/">
                Sandwich dYdX Onboarding
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                        <li className='nav-item'>
                        </li>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
