import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Redirect } from "react-router-dom";
import { Home } from './components/Home';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Route exact path="/" render={() => <Redirect to="/live" />} />
            <Route exact path='/live' component={Home} />
            <Route exact path='/test' component={Home} />
        </Layout>
    );
  }
}
