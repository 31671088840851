﻿import * as React from 'react'
import * as Grid from "./Grid"
import { NavMenu } from './NavMenu';
import * as DateFormat from 'dateformat'
import './Home.css';
import { Input } from 'reactstrap';
import { IError } from './Error';
import { ethers } from 'ethers'
import { disable } from 'workbox-navigation-preload';
import { connect } from 'tls';

interface INetwork {
    id: number,
    name: string;
}

const liveNet: INetwork = { id: 1, name: 'MainNet' };
const testNet: INetwork = { id: 11155111, name: 'Sepolia' };

interface IHomeState {
    walletChainId: number,
    onboardingSignature: ISignature;
    starkKeySignature: ISignature;
}

interface ISignature {
    account: string,
    signature: string,
}

const walletError: number = -1;

export class Home extends React.Component<any, IHomeState> {

    constructor(props) {
        super(props);
        this.state = { walletChainId: null, onboardingSignature: null, starkKeySignature: null };
        this.onNetworkChange = this.onNetworkChange.bind(this);
        this.checkNetworkState = this.checkNetworkState.bind(this);
        this.signOnboardingMessage = this.signOnboardingMessage.bind(this);
        this.signStarkKeyMessage = this.signStarkKeyMessage.bind(this);
        this.copyOnboardingSignature = this.copyOnboardingSignature.bind(this);
        this.copyStarkKeySignature = this.copyStarkKeySignature.bind(this);
    }

    async checkNetworkState() {
        var chainId: number = null;
        try {
            const provider = this.getProvider();
            const network = await provider.getNetwork();
            chainId = network.chainId;
        } catch {
            chainId = walletError;
        }
        this.setState({ walletChainId: chainId });
    }

    async componentDidMount() {
        document.title = "Sandwich dYdX Onboarding";
        setInterval(this.checkNetworkState, 750);
    }

    render(): JSX.Element {
        return (<span><NavMenu />{this.renderContent()}</span>);
    }

    delay(ms: number): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getProvider() {
        var wnd = window as any;
        if (!wnd.ethereum) {
            throw new Error('No crypto wallet found. Please install a crypto wallet to continue.');
        }
        return new ethers.providers.Web3Provider(wnd.ethereum, "any");
    }

    onNetworkChange(chainId: number) {
        switch (chainId) {
            case liveNet.id:
                this.props.history.push("/live");
                break;
            case testNet.id:
                this.props.history.push("/test");
                break;
            default:
        }
    }

    getCurrentNetwork(): INetwork {
        if (this.props.location.pathname.startsWith('/test')) {
            return testNet;
        } else {
            return liveNet;
        }
    }

    hexToDecimal = hex => parseInt(hex, 16);

    fixSignatureV(signature: string) {
        var v = this.hexToDecimal(signature.substring(signature.length - 2, signature.length));
        if (v < 27) {
            v += 27;
            signature = signature.substring(0, signature.length - 2) + v.toString(16).padStart(2, '0');
        }
        return signature;
    }

    async signOnboardingMessage() {
        var currentNetwork = this.getCurrentNetwork();
        const eip712Message = await (await fetch('api/onboardingMessage?chainId=' + currentNetwork.id)).text();
        const provider = this.getProvider();
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        const signature = await signer.provider.send("eth_signTypedData_v4", [account, eip712Message]);
        this.setState({ onboardingSignature: { account: account, signature: this.fixSignatureV(signature) } });
    }

    async signStarkKeyMessage() {
        var currentNetwork = this.getCurrentNetwork();
        const eip712Message = await (await fetch('api/starkKeyMessage?chainId=' + currentNetwork.id)).text();
        const provider = this.getProvider();
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        const signature = await signer.provider.send("eth_signTypedData_v4", [account, eip712Message]);
        this.setState({ starkKeySignature: { account: account, signature: this.fixSignatureV(signature) } });
    }

    copyOnboardingSignature(event) {
        navigator.clipboard.writeText(this.state.onboardingSignature.signature).catch(error => {
            alert('Unable to copy to clipboard. Please copy manually.');
        });
        event.preventDefault();
    }
    copyStarkKeySignature(event) {
        navigator.clipboard.writeText(this.state.starkKeySignature.signature).catch(error => {
            alert('Unable to copy to clipboard. Please copy manually.');
        });
        event.preventDefault();
    }

    renderContent(): JSX.Element {

        var currentNetwork = this.getCurrentNetwork();
        var walletChainId = this.state.walletChainId;
        
        var walletIndicator: JSX.Element = <i className="bi bi-three-dots" />;
        var networkIndicator: JSX.Element = <i className="bi bi-three-dots" />;
        var signOnboarding: JSX.Element = <i className="bi bi-three-dots" />;
        var signStarkKey: JSX.Element = <i className="bi bi-three-dots" />;
        var onboardingSignature: JSX.Element = null;
        var starkKeySignature: JSX.Element = null;

        var showOnboardingSignature = (state: IHomeState) => {
            onboardingSignature = <tr>
                <td></td>
                <td>
                    <textarea disabled style={{ height: 100, marginBottom: 15 }} className="form-control" id="onboardingSignature" value={state.onboardingSignature.signature} />
                    Please copy and paste this signature in Sandwich.
                </td>
                <td><button type="button" className="btn btn-success btn-sm" onClick={e => this.copyOnboardingSignature(e)}>Copy</button></td>
            </tr>;
        };
        showOnboardingSignature = showOnboardingSignature.bind(this);

        var showStarkKeySignature = (state: IHomeState) => {
            starkKeySignature = <tr>
                <td></td>
                <td>
                    <textarea disabled style={{ height: 100, marginBottom: 15 }} className="form-control" id="starkKeySignature" value={state.starkKeySignature.signature} />
                    Please copy and paste this signature in Sandwich.
                </td>
                <td><button type="button" className="btn btn-success btn-sm" onClick={e => this.copyStarkKeySignature(e)}>Copy</button></td>
            </tr>;
        };
        showStarkKeySignature = showStarkKeySignature.bind(this);

        switch (this.state.walletChainId) {
            case walletError:
                walletIndicator = <i className="bi bi-x" />;
                break;
            default:
                walletIndicator = <i className="bi bi-check" />;
                var connected = currentNetwork.id == walletChainId;
                if (!connected) {
                    networkIndicator = <i className="bi bi-x"></i>;
                } else {
                    networkIndicator = <i className="bi bi-check"></i>;
                    if (this.state.onboardingSignature == null) {
                        signOnboarding = <button type="button" className="btn btn-primary btn-sm" onClick={this.signOnboardingMessage}>Sign</button>;
                    } else if (this.state.starkKeySignature == null) {
                        signOnboarding = <i className="bi bi-check"></i>;
                        signStarkKey = <button type="button" className="btn btn-primary btn-sm" onClick={this.signStarkKeyMessage}>Sign</button>;
                        showOnboardingSignature(this.state);
                    }
                    else {
                        signOnboarding = <i className="bi bi-check"></i>;
                        signStarkKey = <i className="bi bi-check"></i>;
                        showOnboardingSignature(this.state);
                        showStarkKeySignature(this.state);
                    }
                }
                break;
        }

        var step = 1;

        return (<div className="container">
                  <div className="row">
                <div className="col">

                    <h2>Add dYdX API Key to Sandwich</h2>

                    <div>
                        <p>
                            <br />
                            In order to obtain a dYdX API key, you need to prove that you have the private key for your ETH address.
                        </p>
                        <p>
                            Sandwich does not need to access to your private key, instead we will ask your trusted crypto wallet to provide this prove by signing two messages.
                        </p>
                        <p>
                            The signing process here is similar to the onboarding process on dYdX's website where the following two messages needs to be signed:
                            <ol type="1">
                                <li>"dYdX Onboarding" (mandatory)<br/>- This will provide read-only access with the ability to cancel orders.</li>
                                <li>dYdX STARK Key" (optional)<br />-This will provide full read-write access with trading ability.</li>
                            </ol>
                        </p>
                        <p>
                            Signing is free and does not create a transaction on the chain.<br/><br/>
                        </p>
                    </div>

                    <div className="steps bg-light border rounded-3 d-inline-block">
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <td>Step {step++}</td>
                                    <td>Network selected from Sandwich's "Add New Key" dialog:</td>
                                    <td>
                                        <Network chainId={currentNetwork.id} onChange={this.onNetworkChange} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Step {step++}</td>
                                    <td>Connected to browser wallet</td>
                                    <td>
                                        {walletIndicator}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Step {step++}</td>
                                    <td>Make sure your wallet is connected to the <strong>{currentNetwork.name}</strong> network</td>
                                    <td>
                                        {networkIndicator}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Step {step++}</td>
                                    <td>Create Onboarding signature (read-only access with the ability to cancel orders)</td>
                                    <td>{signOnboarding}</td>
                                </tr>
                                {onboardingSignature}
                                <tr>
                                    <td></td>
                                    <td><i>...and optionally...</i></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Step {step++}</td>
                                    <td>Create STARK Key signature (full read-write access with trading ability)</td>
                                    <td>{signStarkKey}</td>
                                </tr>
                                {starkKeySignature}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>);
    }

}


interface INetworkProps {
    chainId: number;
    onChange: INetworkChange;
    disabled: boolean;
}

interface INetworkChange {
    (chainId: number): void;
}

interface INetworkState {
}

class Network extends React.Component<INetworkProps, INetworkState> {

    constructor(props: INetworkProps) {
        super(props);
        this.liveClicked = this.liveClicked.bind(this);
        this.testClicked = this.testClicked.bind(this);
    }

    liveClicked() {
        this.props.onChange(liveNet.id); 
    }
    testClicked() {
        this.props.onChange(testNet.id); 
    }

    render() {

        var liveChecked = this.props.chainId != testNet.id;
        return (<div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={liveChecked} onClick={this.liveClicked} disabled={this.props.disabled} />
            <label className="btn btn-outline-primary" htmlFor="btnradio1">Live</label>
            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" checked={!liveChecked} onClick={this.testClicked} disabled={this.props.disabled} />
            <label className="btn btn-outline-primary" htmlFor="btnradio2">Test</label>
        </div>);
    }

}
